<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('materialsAdd')"
          @click="$router.push('materials/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('materialsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه المخازن
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column field="contractsId.code" header="رقم العقد" :sortable="true" />
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
      />

      <Column
        field="id"
        header="تم الطلب"
        v-if="$checkRoles('orderDone') || $checkRoles('orderDoneEdit')"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch
           v-tooltip="'تم الطلب'"
            v-model="slotProps.data.orderDone"
            :disabled="slotProps.data.orderDone"
            @change="updateStatusOne(slotProps.data)"
          />
          {{ slotProps.data.orderDoneDate }}
        </template>
      </Column>
      <Column
        field="id"
        v-if="$checkRoles('editDone') || $checkRoles('editDoneEdit')"
        header="تم التجهيز"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch

           v-tooltip="'تم التجهيز'"
            v-model="slotProps.data.editDone"
            :disabled="slotProps.data.editDone || !slotProps.data.orderDone"
            @change="updateStatusTwo(slotProps.data)"
          />

          {{ slotProps.data.editDoneDate }}
        </template>
      </Column>
      <Column
        field="id"
        v-if="$checkRoles('chargedDone') || $checkRoles('chargedDoneEdit')"
        header="تم الشحن"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          <InputSwitch

           v-tooltip="'تم الشحن'"
            v-model="slotProps.data.chargedDone"
            :disabled="slotProps.data.chargedDone || !slotProps.data.editDone"
            @change="updateStatusThree(slotProps.data)"
          />
          {{ slotProps.data.chargedDoneDate }}
        </template>
      </Column>
      <Column field="id" bodyStyle="text-align: center; overflow: visible">
        <template #body="slotProps">
          <Button
            type="button"
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            v-if="$checkRoles('materialsEdit')"
            @click="openNote(slotProps.data.id)"
            class="p-button-success p-ml-2  p-button-rounded"
          ></Button>

          <!-- <Button
            type="button"
            icon="pi pi-trash" v-tooltip="'حذف'"
            v-if="$checkRoles('materialsDelete')"
            @click="deleteItem(slotProps.data.id)"
            class="p-button-warning  p-button-rounded"
          ></Button> -->
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <Column
              field="contractsId.materialsNote"
              header="المواد الخام"
            >
              <template #body="slotProps">
                <p v-html="slotProps.data.contractsId.materialsNote"></p>
              </template>
            </Column>
          </DataTable>

          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column
              field="contractsId.branchesId.name"
              header="اسم الفرع"
              :sortable="true"
            />
            <Column
              field="contractsId.date"
              header="تاريخ العقد"
              :sortable="true"
            />
            <Column field="contractsId.note" header="ملاحظات العقد" />
            <Column field="note" header="ملاحظات المخزن" />
          </DataTable>
        </div>
      </template>
    </DataTable>
    <Dialog
      header="ملاحظات المخزن"
      :visible.sync="displayBasicToNote"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="note" class="form-label">
              ملاحظات
            </label>
            <textarea class="form-control" id="note" v-model="note" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="حفظ" icon="pi pi-check" @click="saveNote" autofocus />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToNote = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],
      displayBasic: false,
      displayBasicToInstalltions: false,
      expandedRows: [],
      loading: true,
      id: null,
      note: null,
      displayBasicToNote: false,
    };
  },
  methods: {
    openNote(id) {
      this.id = id;
      this.note = null;
      this.displayBasicToNote = true;
    },
    saveNote() {
      if (this.note) {
        this.displayBasicToNote = false;
        this.$http
          .put(`delivery/${this.id}`, {
            note: this.note,
          })
          .then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التحويل بنجاح',
                life: 3000,
              });

              this.getData();
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },
    updateStatusOne(data) {
      this.$http
        .put(`materials/${data.id}`, {
          ...data,
          orderDoneDate: new Date(),
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    updateStatusTwo(data) {
      this.$http
        .put(`materials/${data.id}`, {
          ...data,
          editDoneDate: new Date(),
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },
    updateStatusThree(data) {
      this.$http
        .put(`materials/${data.id}`, {
          ...data,
          chargedDoneDate: new Date(),
        })
        .then(
          () => {
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    getData() {
      this.$http.get(`materials/getAllData`).then(
        (response) => {
          this.loading = false;
          this.list = response.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    deleteAll() {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http
            .delete(`materials/deleteList`, {
              idList: this.selectedItems,
            })
            .then(
              () => {
                this.getData();
                this.$toast.add({
                  severity: 'error',
                  summary: 'تم بنجاح',
                  detail: 'تم الحذف بنجاح',
                  life: 3000,
                });
              },
              (err) => {
                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
        },
      });
    },
    deleteItem(id) {
      this.$confirm.require({
        message: 'هل انتا متاكد انك تريد حذف السجلات',
        header: 'تأكيد الحذف',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$http.delete(`materials/${id}`).then(
            () => {
              this.getData();
              this.$toast.add({
                severity: 'error',
                summary: 'تم بنجاح',
                detail: 'تم الحذف بنجاح',
                life: 3000,
              });
            },
            (err) => {
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        },
      });
    },
  },
  created() {
     if (!this.$checkRoles('materialsVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
